import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import { graphql } from "gatsby"
import marked from "marked"
const PageTemplate = data => {
	const page = data.data.sanityPage
	return (
		<Layout>
			<SEO title={page.metaTitle} description={page.metaDescription} />
			<div
				class="page-headline"
				style={
					page.mainImage &&
					page.mainImage.asset &&
					page.mainImage.asset.fluid &&
					page.mainImage.asset.fluid.src
						? {
								backgroundImage: `url(${page.mainImage &&
									page.mainImage.asset &&
									page.mainImage.asset.fluid &&
									page.mainImage.asset.fluid.src})`,
						  }
						: null
				}
			>
				<div class="container">
					<div class="section-heading text-center">
						{/*<h1>{page.title}</h1>*/}
					</div>
				</div>
			</div>
			<div class="page-content">
				<div class="container">
					<div className="intro mb-4 ">
						<div className="row">
							<div className="col-md-9 mx-auto text-center">
								<h1 className="text-primary">
									<span className="text-orange">{page.title}</span>
								</h1>
								<p className="text-muted">
									{" "}
									<strong />{" "}
								</p>
								<hr />
							</div>
						</div>
					</div>
					<div
						className="pages-template"
						dangerouslySetInnerHTML={{ __html: marked(page.body) }}
					></div>
				</div>
			</div>
		</Layout>
	)
}

export default PageTemplate

export const pageQuery = graphql`
	query pageQuery($id: String!) {
		sanityPage(id: { eq: $id }) {
			id
			title
			slug {
				current
			}
			body
			metaDescription
			metaTitle
			mainImage {
				asset {
					fluid {
						src
					}
				}
			}
		}
	}
`
